<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Special Earning Deductions</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'earning-deductions'}">Earning Deductions</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Special Earning Deductions</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="3">

                    <b-form-group
                        label="Year"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        class="mb-1"
                        label-for="yearfilter"
                        style="font-weight:bold;font-size:1rem !important;"
                    >
                        <b-input-group >
                            <b-form-select 
                                id="yearfilter" 
                                v-model="urlBody.year" 
                                :options="years" 
                                disabled-field="notEnabled"
                                >
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col lg="3">
                    <b-form-group
                        label="Month"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        class="mb-1"
                        label-for="monthfilter"
                        style="font-weight:bold;font-size:1rem !important;"
                    >
                        <b-input-group >
                            <b-form-select 
                                id="monthfilter" 
                                v-model="urlBody.month" 
                                :options="months" 
                                disabled-field="notEnabled"
                                >
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col lg="2">
                        <button
                            type="button"
                            class="btn btn-primary text-left "
                            v-on:click="onSelection()"
                        >Filter</button>
                </b-col>

                <b-col lg="4" class="mb-1rem text-right">
                    <b-form-group
                        label="Employee"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-1"
                        label-for="employeefilter"
                        style="font-weight:bold;font-size:1rem !important;"
                    >
                         <b-input-group size="md" >
                            <div class="col-md-10">
                                <EmployeeList @emp_id="getEmpId" ref="employeeId"></EmployeeList>
                            </div>
                            <b-button
                                :disabled="!filter"
                                @click="onFiltered"
                                class="btn btn-primary col-md-2 text-right"
                                variant="primary"
                            >Search</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <br>

            <div >
                <b-card>
                    <b-row>
                        <b-col lg="6" class="mb-1rem">
                            <router-link
                                v-if="checkPermit('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                :to="{name: 'create-special-earning', 
                                        params: { type:'earning', month: this.urlBody.month, year: this.urlBody.year}}"
                                class="btn btn-primary text-right"
                                tag="button"
                            >
                                <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Special Earning
                            </router-link>
                        </b-col>
                    </b-row>
                    <b-table
                        show-empty
                        striped
                        hover
                        :items="items_one"
                        :fields="fields_one"
                        no-local-sorting
                        @sort-changed="customSorting"
                        @filtered="onFiltered"
                        responsive
                    >
                        <template v-slot:cell(actions)="row">
                            <span class="actions">
                                <router-link
                                    class="circle"
                                    v-b-tooltip.hover title="Edit"
                                    v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS') && row.item.status"
                                    :to="{ name: 'edit-special-earning', params: { id:  row.item.id, type:'earning', month: row.item.month, year: row.item.year}}"
                                >
                                    <font-awesome-icon icon="pen"></font-awesome-icon>
                                </router-link>
                                <span class="ml-1 circle text-danger" @click="statusInfoModal(row.item, row.index, $event.target)" v-b-tooltip.hover title="Deactivate" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS') && row.item.status">
                                    <font-awesome-icon
                                        icon="ban"
                                        v-b-modal.modal-center
                                    ></font-awesome-icon>
                                </span>
                                <span class="ml-1 circle" @click="openHistory(row.item)"  v-b-tooltip.hover title="History">
                                    <font-awesome-icon
                                        icon="history"
                                        v-b-modal.modal-center
                                        style="cursor: pointer;" 
                                    ></font-awesome-icon>
                                </span>
                            </span>
                           
                        </template>
                        <template v-slot:cell(emp_id)="row">
                            {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}]
                        </template>
                        <template v-slot:cell(comment)="row">
                            <p class="text-truncate" style="width:70px" v-b-tooltip.hover :title="row.item.comment">
                                {{row.item.comment}}
                            </p>
                        </template>
                        <template v-slot:cell(status)="row">
                            <h6 v-if="row.item.status"><b-badge variant="success">ACTIVE</b-badge></h6>
                            <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                        </template>
                    </b-table>
                </b-card>
            </div>

            <br> <hr> <br>

            <div>
                <b-card>
                    <b-row>
                        <b-col lg="6" class="mb-1rem">
                            <router-link
                                v-if="checkPermit('CAN_MANAGE_EARNING_DEDUCTIONS')"
                                :to="{name: 'create-special-earning', 
                                        params: { type:'deduction', month: this.urlBody.month, year: this.urlBody.year}}"
                                class="btn btn-primary text-right"
                                tag="button"
                            >
                                <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Special Deduction
                            </router-link>
                        </b-col>
                    </b-row>
                    <b-table
                        show-empty
                        striped
                        hover
                        :items="items_two"
                        :fields="fields_two"
                        no-local-sorts
                        @sort-changed="customSorting_two"
                        @filtered="onFiltered"
                        responsive
                    >
                        <template v-slot:cell(actions)="row">
                            <span class="actions">
                                <router-link
                                    v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS') && row.item.status"
                                    class="circle"
                                    v-b-tooltip.hover title="Edit"
                                    :to="{ name: 'edit-special-earning', params: { id:  row.item.id, type:'deduction', month: row.item.month, year: row.item.year}}"
                                >
                                    <font-awesome-icon icon="pen"></font-awesome-icon>
                                </router-link>
                            <span class="ml-1 circle text-danger" @click="statusInfoModal(row.item, row.index, $event.target)" v-b-tooltip.hover title="Deactivate" v-if="hasPermission('CAN_MANAGE_EARNING_DEDUCTIONS') && row.item.status">
                                <font-awesome-icon
                                    icon="ban"
                                    v-b-modal.modal-center
                                ></font-awesome-icon>
                            </span>
                            <span class="ml-1 circle" @click="openHistory(row.item)"  v-b-tooltip.hover title="History">
                                <font-awesome-icon
                                    icon="history"
                                    v-b-modal.modal-center
                                    style="cursor: pointer;" 
                                ></font-awesome-icon>
                            </span>
                            </span>
                             
                        </template>
                        <template v-slot:cell(emp_id)="row">
                            IES-{{row.item.emp_id}} [{{row.item.employee.full_name}}]
                        </template>
                        <template v-slot:cell(comment)="row">
                            <p class="text-truncate" style="width:70px" v-b-tooltip.hover :title="row.item.comment">
                                {{row.item.comment}}
                            </p>
                        </template>
                        <template v-slot:cell(status)="row">
                            <h6 v-if="row.item.status"><b-badge variant="success">ACTIVE</b-badge></h6>
                            <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                        </template>
                    </b-table>
                </b-card>
            </div>

            <br> <br>

            <!-- <b-row align-h="between" v-if="totalRows">
                <b-col lg="4" class="p-3">
                    <div class="row input-group">
                        <div class="input-group-append my-2">Showing</div>
                        <div class="mx-1em">
                            <select
                                class="form-control"
                                v-model="urlBody.per_page"
                                @change="changePageCount"
                            >
                                <option
                                    v-for="option in pageOptions"
                                    :key="option.index"
                                >{{option}}</option>
                            </select>
                        </div>
                        <div class="input-group-append my-2" v-if="totalRows>urlBody.per_page">of {{totalRows}}</div>
                    </div>
                </b-col>
                <b-col lg="4" class="p-3">
                    <nav aria-label="Page navigation" class="justify-content-center">
                        <ul class="pagination float-right">
                            <li class="page-item">
                                <span
                                    class="page-link"
                                    v-on:click="pagination(urlBody.page - 1)"
                                    v-if="urlBody.page > 1"
                                    style="cursor:pointer"
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-else
                                >
                                    <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                </span>
                            </li>
                            <li class="page-item">
                                <span
                                    class="page-link text-muted"
                                    style="cursor:not-allowed !important;"
                                    v-if="urlBody.page == lastPage"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                                <span
                                    class="page-link"
                                    v-else
                                    v-on:click="pagination(urlBody.page +1)"
                                    style="cursor:pointer"
                                >
                                    Next
                                    <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                </span>
                            </li>
                        </ul>
                    </nav>
                </b-col>
            </b-row> -->
              <!-- History modal -->
             <b-modal 
                :id="history.id" 
                centered  
                size="xl" 
                hide-footer
            >
                <template #modal-title>
                    History
                </template>
                <b-table
                    show-empty
                    striped
                    hover
                    :items="history.rows"
                    :fields="history.fields"
                    no-local-sorting
                    responsive
                >
                 <template v-slot:cell(status)="row">
                    <h6 v-if="row.item.status =='1'"><b-badge variant="success">ACTIVE</b-badge></h6>
                    <h6 v-else><b-badge variant="danger">INACTIVE</b-badge></h6>
                </template>
                </b-table>
            </b-modal>
            <!-- Info modal -->
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="sm"
                @hide="resetInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancel()">Cancel</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="deleteSpecialEarningDeduction(infoModal.content)"
                        style="margin-right:10px;"
                    >Delete</b-button>
                </div>
            </b-modal>

            <!-- Status modal -->
            <b-modal
                :id="statusModal.id"
                :title="statusModal.title"
                centered
                size="md"
                @hide="resetStatusInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancelStatusModal()">No</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="updateStatusEarningDeduction(statusModal.content)"
                        style="margin-right:10px;"
                    >Yes</b-button>
                </div>
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../../errors/NotFound";
import default_error from "../../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
export default {
    components: {
        NotFound,
        EmployeeList
    },
    data() {
        return {
            urlBody: {
                sort_by: "",
                order: "",
                month: new Date().getMonth()+1,
                year: new Date().getFullYear(),
                type:"",
            },
            success: false,
            updated: false,
            deleted: "",
            api_error: "",
            sortBy: "emp_id",
            sortDesc: false,
            fields_one: [
                { label:"Employee", key: "emp_id", sortable: true },
                { label: "Earning Name", key: "name", sortable: true },
                { key: "month",formatter: (value) => { return this.getmonth(value)} ,sortable: true },
                { key: "year", sortable: false },
                { label:"Amount", key: "value", sortable: false },
                { key: "comment", sortable: false },
                { key: "status", sortable: false },
                { key: "actions", label: "Actions" } 
            ],

            fields_two: [
                { label:"Employee", key: "emp_id", sortable: true },
                { label:"Deduction Name", key: "name", sortable: true },
                { key: "month",formatter: (value) => { return this.getmonth(value)} ,sortable: true },
                { key: "year", sortable: false },
                { label:"Amount", key: "value", sortable: false },
                { key: "comment", sortable: false },
                { key: "status", sortable: false },
                { key: "actions", label: "Actions" } 
            ],
            months: [
                {value: "", text:"Select Month"},
                {value: "1", text: "January"},
                {value: "2", text: "February"},
                {value: "3", text: "March"},
                {value: "4", text: "April"},
                {value: "5", text: "May"},
                {value: "6", text: "June"},
                {value: "7", text: "July"},
                {value: "8", text: "August"},
                {value: "9", text: "September"},
                {value: "10", text: "October"},
                {value: "11", text: "November"},
                {value: "12", text: "December"},

            ],
            items_one: null,
            items_two: null,
            totalRows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            statusModal: {
                id: "status-modal",
                title: "",
                content: ""
            },
            years: [
                {value: "", text: "Select Year"},
                {value: new Date().getFullYear()-5, text: new Date().getFullYear()-5 },
                {value: new Date().getFullYear()-4, text: new Date().getFullYear()-4 },
                {value: new Date().getFullYear()-3, text: new Date().getFullYear()-3 },
                {value: new Date().getFullYear()-2, text: new Date().getFullYear()-2 },
                {value: new Date().getFullYear()-1, text: new Date().getFullYear()-1 },
                {value: new Date().getFullYear(), text: new Date().getFullYear() },
                {value: new Date().getFullYear()+1, text: new Date().getFullYear()+1},
            ],
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
            history: {
                id: 'history-modal',
                title: 'History',
                fields: [
                    { label: "Earning Name", key: "name", sortable: true },
                    { key: "month",formatter: (value) => { return this.getmonth(value)} ,sortable: true },
                    { key: "year", sortable: false },
                    { label:"Amount", key: "value", sortable: false },
                    { key: "comment", sortable: false },
                     { key: "status", sortable: false },
                    { label: "Updated by",key: "updated_by_name", sortable: false },
                    { key: "updated_at", sortable: false },
                ],
                rows: []
            },
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_EARNING_DEDUCTIONS");
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        getEmpId(id) {
            this.filter = id;
        },
        openHistory(item) {
            let history = JSON.parse(item.history);
            this.history.rows = history;
            this.$root.$emit("bv::show::modal", this.history.id);
        },
        getmonth(month) {
            var month_name = this.months[month]
            return month_name.text;
        },
        statusInfoModal(item, index, button) {
            this.statusModal.title = `Status Confirmation`;
            this.message = 'Are you sure you want to Deactivate <b>'+this.str_title(item.name)+' </b> Special '+this.str_title(item.type)+ '?';
            this.statusModal.content = item;
            this.$root.$emit("bv::show::modal", this.statusModal.id, button);
        },

        resetStatusInfoModal() {
            this.statusModal.title = "";
            this.statusModal.content = "";
        },

        updateStatusEarningDeduction: function(EarningDeductions) {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            let data = {
                id: EarningDeductions.id,
                status:0
            }
            this.axios
                .post(
                    this.$api.update_status_special_group,
                    data,
                    query
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.statusModal.id);
                        this.success = response.data.message;
                        this.onPageLoad();
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },

        cancelStatusModal: function() {
            this.$root.$emit("bv::hide::modal", this.statusModal.id);
        },

        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete <b>'+item.employee.full_name+'</b>?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered() {
           if (this.filter) {
                this.$router.push({name:'special-earnings', query:{ query: this.filter }});
                this.getSpecialEarnings({query:this.filter});
                this.getSpecialDeductions({query:this.filter}); 
            }
            this.urlBody.month = "";
            this.urlBody.year = "";
        },

        onSelection() {
            var query = this.deleteEmptyKeys({month:this.urlBody.month,year:this.urlBody.year});
            this.getSpecialEarnings(query);
            this.getSpecialDeductions(query);
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: query
                }).catch(()=>{});
             this.$refs.employeeId.reset();
        },

        onPageLoad:function(){   
            var urlBody =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.deleteEmptyKeys(this.urlBody); 
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: urlBody
                }).catch(()=>{});
            this.getSpecialEarnings(urlBody); 
            this.getSpecialDeductions(urlBody);
            if(!urlBody.month){
                this.urlBody.month = "";
            }
            if(!urlBody.year){
                this.urlBody.year = "";
            }
        },

        getSpecialEarnings: function(urlBody) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.urlBody = urlBody;
            this.urlBody.type= "earning";
            query['params']=this.deleteEmptyKeys(this.urlBody);
            this.axios
                .get(this.$api.get_special_groups, query)
                .then(response => {
                    const result = response.data.data;
                    this.items_one = result;
                    this.api_error = "";
                    if (urlBody.query) {
                        this.$refs.employeeId.getSelectedEmployee(urlBody.query);
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        getSpecialDeductions: function(urlBody) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.urlBody = urlBody;
            this.urlBody.type= "deduction";
            query['params']=this.deleteEmptyKeys(this.urlBody);
            this.axios
                .get(this.$api.get_special_groups, query)
                .then(response => {
                    const result = response.data.data;
                    this.items_two = result;
                    this.api_error = "";
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        deleteSpecialEarningDeduction: function(SpecialEarningDeduction) {
            this.startProgressBar();
            this.axios
                .post(
                    this.$api.delete_special_group,
                    JSON.parse(SpecialEarningDeduction),
                    this.getAuthHeaders()
                )
                .then(response => {
                    if (response.data.code == 200) {
                        this.$root.$emit("bv::hide::modal", this.infoModal.id);
                        this.deleted = "User Deleted Successfully.";
                        this.getSpecialEarnings();
                    }
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },
        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },
        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            this.api_error = "";
            this.getSpecialEarnings(urlBody);
            this.$router.push({name:'special-earnings', query:urlBody});
            if(!urlBody.month){
                this.urlBody.month = "";
            }
            if(!urlBody.year){
                this.urlBody.year = "";
            }
        },

        customSorting_two: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            this.api_error = "";
            this.getSpecialDeductions(urlBody);
            this.$router.push({name:'special-earnings', query:urlBody });
            if(!urlBody.month){
                this.urlBody.month = "";
            }
            if(!urlBody.year){
                this.urlBody.year = "";
            }
        },

        checkPermit: function(permission) {
            return this.hasPermission(permission);
        },

        clearSearch (event) {
            if (event.target.value === ""){
                if (this.urlBody.query != ''){
                    this.urlBody.year = new Date().getFullYear()
                    this.urlBody.month = new Date().getMonth()+1;
                    var newQuery = {year: this.urlBody.year, month: this.urlBody.month}
                    this.getSpecialEarnings(newQuery);
                    this.getSpecialDeductions(newQuery);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: {year: this.urlBody.year, month: this.urlBody.month}
                        })
                }
            }
        },

    }
};
</script>
<style lang="scss">
@import "../../../../assets/css/custom.scss";
</style>
